body {
  margin: 0;
  padding: 0;
  color: #d2d2d2;

  font-family: 'Roboto', 'Libre Baskerville', serif;
  font-size: 18px;
  background-color: #252629;
}

a {
  color: #d2d2d2;
}

